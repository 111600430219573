import { render, staticRenderFns } from "./applyFor.vue?vue&type=template&id=6d38886e&scoped=true&"
import script from "./applyFor.vue?vue&type=script&lang=js&"
export * from "./applyFor.vue?vue&type=script&lang=js&"
import style0 from "./applyFor.vue?vue&type=style&index=0&id=6d38886e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d38886e",
  null
  
)

export default component.exports